import { ayuntamiento } from './ayuntamiento';
import { BaseDto } from "./base-dto";
export class usuario extends BaseDto {
  public email!: string;
  public contra!: string;
  public nombre!: string;
  public id_ayuntamiento!: number;
  public ayuntamiento!: ayuntamiento;
  public is_admin!:boolean;
  public solo_datos!:boolean;
  public telefono!: string;
  public cargo!: string;
}
