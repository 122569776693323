import { store } from "@/store/store";
import { usuario } from "@/shared/dtos/usuario";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { changepwd } from "@/shared/dtos/changepwd";

@Module({
  namespaced: true,
  name: "usuarioModule",
  store,
  dynamic: true,
})
class usuarioModule extends VuexModule {
  public usuarios: usuario[] = [];
  public usuario: usuario = new usuario();
  public mi_usuario: usuario = new usuario();
  public permisos:string[]=[];

  @Action({ commit: "onGetusuarios" })
  public async getusuarios() {
    return await ssmHttpService.get(API.usuario);
  }

  @Action({ commit: "onGetusuario" })
  public async getusuario(id: any) {
    return await ssmHttpService.get(API.usuario + "/" + id);
  }

  @Action({ commit: "onGetmi_usuario" })
  public async getmiusuario() {
    return await ssmHttpService.get(API.usuario + "/mi_user/");
  }
  @Action({ commit: "onGetpermisos" })
  public async getpermisos() {
    return await ssmHttpService.get(API.usuario + "/permisos/");
  }
  

  @Action
  public async guardarusuario(usuario: usuario) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(API.usuario, usuario.toJson(),true,false,true);
    this.getusuarios();
  }

  @Action
  public async change_pwdusuario(change: changepwd) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(
      API.usuario + "/change_pwd/",
      change.toJson(),
      true,
      false,
      true
    );
  }

  @Action
  public async modificarusuario(usuario: usuario) {
    await ssmHttpService.put(API.usuario + "/" + usuario.id, usuario,true,false,true);
    this.getusuarios();
  }

  @Action
  public async modificarmi_usuario(usuario: usuario) {
    return await ssmHttpService.put(API.usuario + "/" + usuario.id, usuario,true,false,true);
  }

  @Action
  public async eliminarusuario(usuario: usuario) {
    await ssmHttpService.delete(API.usuario + "/" + usuario.id, null, false);
    this.getusuarios();
  }

  @Mutation
  public onGetusuarios(res: usuario[]) {
    this.usuarios = res ? res.map((x) => new usuario(x)) : [];
  }
  
  @Mutation
  public onGetpermisos(res: string[]) {
    this.permisos = res;
  }

  @Mutation
  public onGetusuario(res: usuario) {
    this.usuario = new usuario(res);
  }
  @Mutation
  public onGetmi_usuario(res: usuario) {
    this.mi_usuario = new usuario(res);
  }
}
export default getModule(usuarioModule);
